import React, { useState } from "react";
import styled from "styled-components";

import AuthUserContext from "components/Session/AuthUserContext";
import withAuthorization from "components/Session/withAuthorization";

import { Container, Header, Next } from "components/Onboarding/OnboardingComponents";

import { pushOnboarding } from "api/db";

function InterestOnboarding() {
  const [option, setOption] = useState(null);

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <Container>
          <Header />
          <section>
            <span>1/3</span>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">What are you interested in? </h1>
              <Next
                paintDrip
                to="/onboarding/questions/motivation"
                hex="#438cee"
                duration={1}
                onClick={() =>
                  pushOnboarding(authUser.uid, "interest", "Web Development")
                }
              >
                Web Development
              </Next>
              <br />
              <Next
                paintDrip
                to="/onboarding/questions/motivation"
                hex="#438cee"
                duration={1}
                onClick={() =>
                  pushOnboarding(authUser.uid, "interest", "Machine Learning")
                }
              >
                Machine Learning
              </Next>
              <br />
              <Next
                paintDrip
                to="/onboarding/questions/motivation"
                hex="#438cee"
                duration={1}
                onClick={() =>
                  pushOnboarding(authUser.uid, "interest", "Both")
                }
              >
                Both
              </Next>
            </div>
          </section>
        </Container>
      )}
    </AuthUserContext.Consumer>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(InterestOnboarding);
